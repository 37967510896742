import React from 'react';
import './AgeVerificationPopup.css';
import Cookies from 'js-cookie'; // Import the js-cookie library

function AgeVerificationPopup({ onAgeVerified }) {
  const handleAgeVerification = (isOver21) => {
    if (isOver21) {
      // Set a cookie named "ageVerified" with value "true" that expires after 1 hour
      Cookies.set('ageVerified', 'true', { expires: 1 / 24 }); // 1 hour = 1/24 days
      onAgeVerified();
    } else {
      alert('You must be at least 21 years old to access this site.');
    }
  };

  // Check if the "ageVerified" cookie exists and is set to "true"
  const isAgeVerified = Cookies.get('ageVerified') === 'true';

  // If user is already verified, don't show the popup
  if (isAgeVerified) {
    onAgeVerified();
    return null;
  }

  return (
    <div className="age-verification-overlay">
      <div className="age-verification-popup">
        <h2>Age Verification</h2>
        <p>Are you over 21 years old?</p>
        <div className="age-verification-buttons">
          <button onClick={() => handleAgeVerification(true)}>Yes</button>
          <button onClick={() => handleAgeVerification(false)}>No</button>
        </div>
      </div>
    </div>
  );
}

export default AgeVerificationPopup;