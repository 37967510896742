import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import AgeVerificationPopup from './AgeVerification/AgeVerificationPopup';
import { fetchProductsAndUpdateGrid, fetchSearchAndUpdateGrid, fetchSuggestions } from './Api';
import BourbonGrid from './BourbonGrid/BourbonGrid';
import { SortTypes, Amount, allSourcesOption } from './Constants/Constants';
import InventoryLastUpdated from './InventoryLastUpdated/InventoryLastUpdated';
import StickyMenu from './StickyMenu/StickyMenu';
import { scrollToTop } from './Utilities/Utilities';
import NavBar from './NavBar/NavBar';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Reviews from './Reviews/Reviews';
import About from './About/About';
import ReviewDetail from './ReviewDetail/ReviewDetail';
import DonationPopup from './DonationPopup/DonationPopup';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, AppInsightsErrorBoundary, withAITracking } from '@microsoft/applicationinsights-react-js';
import AppInsightsContext from './AppInsights/AppInsightsContext';

// Initialize Application Insights
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    connectionString: 'InstrumentationKey=a4e89560-3a9c-4852-8ddf-cf73cf6a79aa;IngestionEndpoint=https://centralus-3.in.applicationinsights.azure.com/;LiveEndpoint=https://centralus.livediagnostics.monitor.azure.com/',
    enableAutoRouteTracking: true,
    extensions: [reactPlugin],
  },
});

appInsights.loadAppInsights();
appInsights.trackPageView(); // Automatically track page views

function App() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [offset, setOffset] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [inStockOnly, setInStockOnly] = useState(false);
  const [sortBy, setSortBy] = useState(SortTypes.dateNewToOld);
  const [okToScroll, setOkToScroll] = useState(true);
  const [fetching, setFetching] = useState(false);
  const notInitial = useRef(false);
  const [ageVerified, setAgeVerified] = useState(false);
  const [minimumPrice, setMinimumPrice] = useState(0);
  const [maximumPrice, setMaximumPrice] = useState(0);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [selectedSources, setSelectedSources] = useState([]);
  const [sourceOptions, setSourceOptions] = useState([]);
  const [allSelected, setAllSelected] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [popupShown, setPopupShown] = useState(false);

  const handleAgeVerified = () => {
    setAgeVerified(true);
  };

  // Track custom events with Application Insights
  const trackEvent = (eventName, properties) => {
    appInsights.trackEvent({ name: eventName, properties });
  };

  function initializePaypalButton() {
    // Ensure PayPal is available and the Donation object exists before initializing
    if (window.PayPal && window.PayPal.Donation) {
      window.PayPal.Donation.Button({
        env: 'production',
        hosted_button_id: 'X8R6ML2JQPXJA',
        image: {
          src: 'https://www.paypalobjects.com/en_US/i/btn/btn_donate_SM.gif',
          alt: 'Donate with PayPal button',
          title: 'PayPal - The safer, easier way to pay online!',
        },
      }).render('#donate-button');
    }
  }

  const handleReset = (event) => {
    setSelectedSources(sourceOptions);
    setAllSelected(true);
    setSearchTerm('');
    setMinimumPrice(0);
    setMaximumPrice(0);
    setInStockOnly(false);
    scrollToTop();
    if (sortBy !== SortTypes.dateNewToOld) {
      setSortBy(SortTypes.dateNewToOld);
    }
    else {
      // sort by is already the default,
      // we need some other way to force a refresh
      setForceRefresh(!forceRefresh);
    }
  };

  useEffect(() => {
    if (!ageVerified) {
      document.body.classList.add('disable-scroll');
    } else {
      document.body.classList.remove('disable-scroll');
    }
  }, [ageVerified]);

  useEffect(() => {
    fetchProductsAndUpdateGrid(inStockOnly,
      sortBy,
      offset,
      searchTerm,
      setLoading,
      setProducts,
      setOffset,
      setOkToScroll,
      fetching,
      setFetching,
      minimumPrice,
      maximumPrice,
      selectedSources,
      appInsights);
  }, [offset]);

  useEffect(() => {
    if (!notInitial.current) //if initial load do nothing
    {
      notInitial.current = true;
    }
    else {
      // If sort by is updated and we have a search term
      // treat it like a search and not a load/fetchproducts
      if (searchTerm) {
        fetchSearchAndUpdateGrid(
          null,
          setProducts,
          offset,
          setOffset,
          setLoading,
          setOkToScroll,
          searchTerm,
          sortBy,
          appInsights);
      }
      else {
        fetchProductsAndUpdateGrid(
          inStockOnly,
          sortBy,
          offset,
          searchTerm,
          setLoading,
          setProducts,
          setOffset,
          setOkToScroll,
          fetching,
          setFetching,
          minimumPrice,
          maximumPrice,
          selectedSources,
          appInsights,
          true);
      }
    }
  }, [sortBy, forceRefresh, selectedSources]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [products]);

  useEffect(() => {
    if (searchTerm.trim() !== '') {
      debouncedFetchSuggestions(searchTerm.trim(), setSuggestions);
    } else {
      setSuggestions([]);
    }
  }, [searchTerm]);

  const debouncedFetchSuggestions = useCallback(debounce(fetchSuggestions, 300), []);

  const handleScroll = debounce(() => {
    if (okToScroll) {
      const scrollTop = document.documentElement.scrollTop;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      if (scrollTop + windowHeight >= documentHeight - 100 && !searchTerm) {
        if (notInitial.current) {
          if (!showPopup) {
            setShowPopup(true); // Show the popup on the first scroll
            // Track the event when the popup is shown
            trackEvent('PopupShown', {});
          }
        }
        setOffset(previousOffset => previousOffset + Amount)
      }
    }
  }, 100);

  const handleClosePopup = () => {
    // Track the event when the popup is closed
    trackEvent('PopupClosed', {});
    setShowPopup(false); // Close the popup
    setPopupShown(true);
  };

  return (
    <AppInsightsErrorBoundary onError={() => <h1>I believe something went wrong</h1>} appInsights={reactPlugin}>
      <Router>
        <NavBar />
        <div className="container">
          <AppInsightsContext.Provider value={appInsights}>
            {!ageVerified && <AgeVerificationPopup onAgeVerified={handleAgeVerified} />}
            {/* Conditionally render the donation popup */}
            {showPopup && !popupShown && <DonationPopup onClose={handleClosePopup} initializePaypalButton={initializePaypalButton} />}
            <InventoryLastUpdated />
            <Routes>
              <Route />
              <Route path='/reviews' element={<Reviews appInsights={appInsights} />} />
              <Route path="/reviews/:id" element={<ReviewDetail />} />
              <Route path='/about' element={<About appInsights={appInsights} />} />
              <Route path='/' element={
                <div>
                  <StickyMenu
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    setInStockOnly={setInStockOnly}
                    setOkToScroll={setOkToScroll}
                    setSortBy={setSortBy}
                    suggestions={suggestions}
                    handleSearch={(e) => fetchSearchAndUpdateGrid(e, setProducts, offset, setOffset, setLoading, setOkToScroll, searchTerm, sortBy, appInsights)}
                    inStockOnly={inStockOnly}
                    sortBy={sortBy}
                    setMaximumPrice={setMaximumPrice}
                    setMinimumPrice={setMinimumPrice}
                    minimumPrice={minimumPrice}
                    maximumPrice={maximumPrice}
                    handleReset={handleReset}
                    selectedSources={selectedSources}
                    setSelectedSources={setSelectedSources}
                    allSelected={allSelected}
                    setAllSelected={setAllSelected}
                    sourceOptions={sourceOptions}
                    setSourceOptions={setSourceOptions}
                    initializePaypalButton={initializePaypalButton}
                  />
                  <BourbonGrid
                    products={products}
                    inStockOnly={inStockOnly}
                    loading={loading}
                    maximumPrice={maximumPrice}
                    minimumPrice={minimumPrice}
                  />
                </div>
              } />
            </Routes>
          </AppInsightsContext.Provider>
        </div>
      </Router>
    </AppInsightsErrorBoundary>
  );
}

export default withAITracking(appInsights, App);
