import React, { useEffect } from 'react';
function About({ appInsights }) {

  useEffect(() => {
    // Track a page view using appInsights
    appInsights.trackPageView({ name: 'About Page', uri: '/about' });
  }, [appInsights]);

  return (
    <div className="about-container">
      <h2>About Us</h2>
      <p>
        Welcome to Bourbon-Finder (DistilleryHub L.L.C.), the premier destination for bourbon enthusiasts. 
        My mission is to provide a centralized tool that scans websites that deliver to you so you can
        easily search and compare prices! I hope to get other bourbon lovers as excited about this tool as I am!
      </p>
      <p>
        I am a lover and collector of bourbon that also has a strong passion for technology. 
        With my platform, I hope to make your hunt easier and get you that bottle you've had your eyes on! Happy hunting!

        If you have any questions or requests please contact me at bourbonfindersite@gmail.com.

        I don't make <b>any</b> money from this website, I just want others to find the bottles they love. If you love our
        service, <b>please consider donating to our site to keep it free and up and running!</b> If you don't enjoy our site, please contact
        us and help us improve! Thanks again everyone!
      </p>

      <p>
        <b>Contact: bourbonfindersite@gmail.com</b>
      </p>
    </div>
  );
}

export default About;
