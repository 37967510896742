import React, { useEffect } from 'react';
import './DonationPopup.css';

function DonationPopup(props) {

    useEffect(() => {
        // Add the 'no-scroll' class to the body when the popup is mounted
        document.body.classList.add('disable-scroll');
        props.initializePaypalButton();

        // Remove the 'no-scroll' class from the body when the popup is unmounted
        return () => {
            document.body.classList.remove('disable-scroll');
        };
    }, []);

    return (
        <div className="donationpopup-overlay">
            <div className="donationpopup-popup">
                <h2>Want more bottles?</h2>
                <p>100% of your donations go to the compute cost of searching the internet for great deals! Please consider donating to be able to find more deals!</p>
                <div className='donationpopup-buttons'>
                    <button onClick={props.onClose}>Close</button>
                    <div style={{ marginLeft: '12px' }} id="donate-button"></div>
                </div>
            </div>
        </div>
    );
}

export default DonationPopup;
