import { useEffect, useState } from "react";
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Card from 'react-bootstrap/Card';
import CurrencyInput from 'react-currency-input-field';
import { Button, Col, Input, Label, Row } from 'reactstrap';
import { SortTypes } from "../Constants/Constants";
import { scrollToTop } from "../Utilities/Utilities";
import { MultiSelect } from "react-multi-select-component";
import { useLayoutEffect } from "react";
import "./StickyMenu.css"

function StickyMenu(props) {
  const [expanded, setExpanded] = useState(true);

  useLayoutEffect(() => {
    // Function to fetch products from the API
    fetchWebsites();
  }, []);

  useEffect(() => {
    try {
        // Check if PayPal script is already loaded
        const existingScript = document.getElementById('paypal-script');
        if (!existingScript) {
            const script = document.createElement('script');
            script.id = 'paypal-script';  // Add an id to the script
            script.src = 'https://www.paypalobjects.com/donate/sdk/donate-sdk.js';
            script.async = true;
            script.onload = props.initializePaypalButton;
            document.body.appendChild(script);
        } else {
            props.initializePaypalButton();
        }
    } catch (err) {
        console.error('Error initializing PayPal:', err);
        return;
    }
}, []);

  function CustomAccordionButton({ children, eventKey }) {

    const handleAccordionClick = () => {
      setExpanded(!expanded);
    };

    const decoratedOnClick = useAccordionButton(eventKey);
    return (
      <button
        type="button"
        style={{ width: '100%' }}
        className="btn btn-link text-decoration-none"
        onClick={(eventKey) => { handleAccordionClick(); decoratedOnClick(); }}
      >
        {children}
      </button>
    );
  }

  const fetchWebsites = async (setSources) => {
    try {
      const response = await fetch(`https://bourbonscraper2.azurewebsites.net/api/websites`,
        {
          headers: {
            'Content-Type': 'application/json',
          }
        });
      let data = await response.json();
      data = data.map(d => getDomain(d.url));
      props.setSourceOptions(data);
      props.setSelectedSources(data);
    } catch (error) {
      console.error('Error fetching websites:', error);
    }
  };

  const getDomain = (url) => {
    if (url.includes("www."))
    {
      const hostname = url.split(".")[1];
      return {value: url, label: hostname};
    }
    const removedProtocol = url.split("//")[1];
    const hostname = removedProtocol.split(".")[0];
    return {value: url, label: hostname};
  };

  const handleSortChange = (event) => {
    props.setOkToScroll(false);
    scrollToTop();
    props.setSortBy(event.target.value);
  };

  const isMobile = window.innerWidth <= 768;
  const getButtonSize = () => (isMobile) ? "sm" : "";

  const handleStockFilterChange = event => {
    props.setInStockOnly(event.target.checked);
  };

  const handleKeyUp = (event) => {
    //key code for enter
    if (event.keyCode === 13) {
      event.preventDefault();
      event.target.blur();
    }
  };

  let expandSrc = expanded ? '/doubleup.png' : '/doubledown.png';

  return (
    <div id="header" className='sticky-top' style={{ padding: '0.5rem', marginBottom: '0.5rem', zIndex: '999', paddingTop: '0' }}>
      <div className="bg-light" style={{ paddingLeft: '0.5rem', paddingRight: '0.5rem', paddingBottom: '.5rem' }}>
        <Accordion defaultActiveKey="0">
          <Card>
            <Card.Header>
              <CustomAccordionButton eventKey="0">
                Search & Filters
                <img src={expandSrc} style={{ height: 'auto', width: '15px', marginLeft: '4px' }} />
              </CustomAccordionButton>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <form className="search-container mb-2" onSubmit={props.handleSearch}>
                  <Row className="align-items-center">
                    <Col md={8} xs={12} className="mb-1 mb-md-0">
                      <Input
                        className='mt-1'
                        type="text"
                        placeholder="Search for products..."
                        value={props.searchTerm}
                        onChange={event => props.setSearchTerm(event.target.value)}
                        onKeyUp={handleKeyUp}
                        style={{ padding: '0.5rem' }}
                        list="suggestions"
                        autoComplete="off"
                      />
                      {props.suggestions.length > 0 && (
                        <datalist id="suggestions">
                          {props.suggestions.map((suggestion, index) => (
                            <option key={index} value={suggestion} />
                          ))}
                        </datalist>
                      )}
                    </Col>
                    <Col md={2} xs={12} className='mt-1'>
                      <Button type="submit" block size={getButtonSize()}>
                        Search
                      </Button>
                    </Col>
                    <Col md={2} xs={12} className='mt-1'>
                      <Button type="reset" block onClick={props.handleReset} size={getButtonSize()}>
                        Reset
                      </Button>
                    </Col>
                  </Row>
                </form>

                <Row className="align-items-center">
                  <Col md={2} xs={12} className="d-flex align-items-center mb-1 mb-md-0">
                    <Input type="checkbox" checked={props.inStockOnly} onChange={handleStockFilterChange} />
                    <Label className="ml-2 mt-3" style={{ marginLeft: '4px' }}>In-Stock Only</Label>
                  </Col>
                  <Col md={6} xs={12} className="d-flex align-items-center mb-1 mb-md-0">
                    <CurrencyInput
                      className='form-control'
                      id="min"
                      name="input-min"
                      placeholder="minimum price"
                      defaultValue={''}
                      allowDecimals={false}
                      prefix='$'
                      step={10}
                      value={props.minimumPrice === 0 ? '' : props.minimumPrice}
                      onValueChange={(value, _) => props.setMinimumPrice(value)}
                      style={{ flexGrow: 1, width: '100%' }}
                    />
                    <CurrencyInput
                      className='form-control'
                      id="max"
                      name="input-max"
                      placeholder="maximum price"
                      defaultValue={''}
                      allowDecimals={false}
                      prefix='$'
                      step={10}
                      value={props.maximumPrice === 0 ? '' : props.maximumPrice}
                      onValueChange={(value, _) => props.setMaximumPrice(value)}
                      style={{ flexGrow: 1, width: '100%', marginLeft: '4px' }}
                    />
                  </Col>

                  <Col md={4} xs={12} className="d-flex align-items-center mb-1 mb-md-0">
                    <Label className="mr-2 mt-3" style={{ marginRight: '4px' }}>Sort:</Label>
                    <Input
                      type="select"
                      value={props.sortBy}
                      onChange={handleSortChange}
                      style={{ flexGrow: 1, width: '100%' }}
                    >
                      <option value={SortTypes.dateNewToOld}>Date, new to old</option>
                      <option value={SortTypes.dateOldToNew}>Date, old to new</option>
                      <option value={SortTypes.priceHighToLow}>Price, high to low</option>
                      <option value={SortTypes.priceLowToHigh}>Price, low to high</option>
                      <option value={SortTypes.alphabeticallyZA}>Alphabetically, Z-A</option>
                      <option value={SortTypes.alphabeticallyAZ}>Alphabetically, A-Z</option>
                    </Input>
                  </Col>
                </Row>

                <Row className="mt-1">
                  <Col md={8} xs={12} className="d-flex align-items-center">
                    <span className="mr-2 mb-2">Buy me a pour:</span>
                    <div style={{ marginLeft: '12px' }} id="donate-button"></div>
                  </Col>
                  <Col md={4} xs={12} className="d-flex align-items-center">
                    <Label className="mr-2 mt-3" style={{ marginRight: '4px' }}>Store:</Label>
                    <MultiSelect
                      options={props.sourceOptions}
                      value={props.selectedSources}
                      onChange={props.setSelectedSources}
                      className="stickyMenuSelect flex-grow-1"
                      hasSelectAll={true}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </div>
    </div>
  );
}

export default StickyMenu;