import React, { createContext, useContext } from 'react';

// Create a context for appInsights
const AppInsightsContext = createContext(null);

// Custom hook to access appInsights from any component
export function useAppInsights() {
  return useContext(AppInsightsContext);
}

export default AppInsightsContext;
