import { useRef, useLayoutEffect } from "react";
import { ordinal_suffix_of } from "../Utilities/Utilities";

function InventoryLastUpdated() {
    const lastRefreshed = useRef("");
    const getLastRefreshed = async () => {
        if (lastRefreshed.current === "") {
            const response = await fetch(
                `https://bourbon-search.search.windows.net/indexes/bourbon-index/docs?api-version=2023-07-01-Preview&search=*&%24top=1&%24skip=0&%24orderby=lastUpdate desc`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'api-key': 'ucao7YGRjMPe4XBtKycuHAb1655HAPiybrDPTqa5NOAzSeBFRzpy'
                    }
                }
            );

            let data = await response.json();
            const currentDate = new Date(data.value[0].lastUpdate);
            const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            lastRefreshed.current = `${monthNames[currentDate.getMonth()]}, ${ordinal_suffix_of(currentDate.getDate())}`;
        }
    };

    useLayoutEffect(() => {
        // Function to fetch products from the API
        getLastRefreshed();
    }, []);

    return (<div style={{ padding: '1rem', marginBottom: '1rem', textAlign: 'center' }}>
        <a href='/'>
            <img src='/logosmall.png' className='mb-0' style={{ width: '70%', height: '70%', paddingLeft: '0' }} alt='logo' />
        </a>
        {lastRefreshed.current && (
            <span className="text-center" style={{ fontFamily: "Roboto", fontSize: "12px", display: "block", marginTop: "10px" }}>
                DistilleryHub L.L.C.<br/>
                Inventory Updated: {lastRefreshed.current}
            </span>
        )}
    </div>);
}

export default InventoryLastUpdated;