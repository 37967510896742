import React from 'react';
import { useParams } from 'react-router-dom';
import { bottleReviews } from '../ReviewData';

function ReviewDetail() {
    const { id } = useParams();
    const review = bottleReviews.find(r => r.id === parseInt(id));

    if (!review) {
        return <div>Review not found</div>;
    }

    return (
        <div className="review-detail-container">
            <h2>{review.bottleName}</h2>
            <p>{review.review}</p>
        </div>
    );
}

export default ReviewDetail;
