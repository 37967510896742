import { allSourcesOption } from "../Constants/Constants";

export const ordinal_suffix_of = (i) => {
    var j = i % 10,
        k = i % 100;
    if (j === 1 && k !== 11) {
        return i + "st";
    }
    if (j === 2 && k !== 12) {
        return i + "nd";
    }
    if (j === 3 && k !== 13) {
        return i + "rd";
    }
    return i + "th";
};

export const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth' // for smoothly scrolling
    });
};

export const getOrderByParams = (sortBy) => {
    let sortParts = sortBy.split("&");
    let option = sortParts[0];
    let order = sortParts[1];

    return [option, order];
};

export const buildSearchQueryParams = (sortBy, inStockOnly, minimumPrice, maximumPrice, selectedSources) => {
    let filterAvailability = (inStockOnly) ? `availability eq ${inStockOnly}` : "";
    let filterMin = (minimumPrice) ? `price_numeric gt ${minimumPrice}` : "price_numeric gt 0";
    let filterMax = (maximumPrice) ? `price_numeric lt ${maximumPrice}` : "";
    let filterSources = `search.in(url, '${getSourcesString(selectedSources)}')` ;
    let queryParamsString = [filterAvailability, filterMin, filterMax, filterSources].filter(function (entry) { return entry.trim() !== ''; }).join(' and ');
    if (queryParamsString !== '') {
        queryParamsString = `$filter=${queryParamsString}`;
    }

    return queryParamsString;
};

const getSourcesString = (selectedSources) => {
    let result = [];
    selectedSources.forEach((item, index, arr)=>{
        result.push(`${item.value}`);
    });

    return result.join(',');
};

export const formatTitle = (title) => title.replace(/&amp;/g, '&').replace(/&#39;/g, '\'');
