import React, { useEffect} from 'react';
import { Link } from 'react-router-dom';
import { bottleReviews } from '../ReviewData';

function Reviews({ appInsights }) {
    useEffect(() => {
        // Track a page view using appInsights
        appInsights.trackPageView({ name: 'Reviews Page', uri: '/reviews' });
      }, [appInsights]);

    return (
        <div className="reviews-list-container">
            <h2>Coming Soon!</h2>
            {/* <ul>
                {bottleReviews.map(review => (
                    <li key={review.id}>
                        <Link to={`/reviews/${review.id}`}>{review.bottleName}</Link>
                    </li>
                ))}
            </ul> */}
        </div>
    );
}

export default Reviews;
