import { Amount } from "./Constants/Constants";
import { buildSearchQueryParams, formatTitle, getOrderByParams, scrollToTop } from "./Utilities/Utilities";

export const fetchProductsAndUpdateGrid = async (inStockOnly,
    sortBy,
    offset,
    searchTerm,
    setLoading,
    setProducts,
    setOffset,
    setOkToScroll,
    fetching,
    setFetching,
    minimumPrice,
    maximumPrice,
    selectedSources,
    appInsights,
    reload = false) => {
    if (!fetching) {
        setFetching(true);
        try {
            setLoading(true);
            let searchText = searchTerm ? encodeURIComponent(searchTerm) : "*";
            let currentOffset = (reload) ? 0 : offset;
            appInsights.trackEvent({
                name: 'ProductLoad',
                properties: {
                  offset: currentOffset, // Include search term or any relevant information
                },
              });
            const [option, order] = getOrderByParams(sortBy);
            const filterParams = buildSearchQueryParams(sortBy, inStockOnly, minimumPrice, maximumPrice, selectedSources);
            const response = await fetch(
                `https://bourbon-search.search.windows.net/indexes/bourbon-index/docs?api-version=2023-07-01-Preview&search=${searchText}&%24top=${Amount}&%24skip=${currentOffset}&%24orderby=${option} ${order}&${filterParams}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'api-key': 'ucao7YGRjMPe4XBtKycuHAb1655HAPiybrDPTqa5NOAzSeBFRzpy'
                    }
                }
            );
            const data = await response.json();
            if (reload) {
                setProducts(data.value);
                setOffset(0);
                setOkToScroll(true);
            }
            else {
                setProducts(prevProducts => [...prevProducts, ...data.value]);
            }
        } catch (error) {
            console.error('Error fetching products:', error);
        }
        finally {
            setLoading(false);
            setFetching(false);
        }
    }
};

export const fetchSuggestions = async (text, setSuggestions) => {
    if (!text) {
        setSuggestions([]);
        return;
    }
    try {
        const response = await fetch(
            `https://bourbon-search.search.windows.net/indexes/bourbon-index/docs/suggest?api-version=2021-04-30-Preview&search=${encodeURIComponent(text)}&suggesterName=nameSuggester`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'api-key': 'ucao7YGRjMPe4XBtKycuHAb1655HAPiybrDPTqa5NOAzSeBFRzpy'
                }
            }
        );
        const data = await response.json();
        setSuggestions(data.value.map(suggestion => formatTitle(suggestion['@search.text'])));
    } catch (error) {
        console.error('Error fetching suggestions:', error);
    }
};

export const fetchSearchAndUpdateGrid = async (event,
    setProducts,
    offset,
    setOffset,
    setLoading,
    setOkToScroll,
    searchTerm,
    sortBy,
    appInsights) => {
    if (event) {
        event.preventDefault();
    }
    scrollToTop();
    // If we do not have a search term and we have not loaded anything on the page
    // We should not do anything.
    if (!searchTerm) {
        if (offset === 0)
        {
            // If we have no search term and the offset hasn't changed
            // meaning we haven't loaded anything.
            // There is no need to do anything.
            return;
        }
        setProducts([]);
        setOffset(0);
        return;
    }
    try {
        setLoading(true);
        appInsights.trackEvent({
            name: 'Search',
            properties: {
              searchTerm: searchTerm, // Include search term or any relevant information
            },
          });
        const [option, order] = getOrderByParams(sortBy);
        const response = await fetch(
            `https://bourbon-search.search.windows.net/indexes/bourbon-index/docs?api-version=2021-04-30-Preview&search=${encodeURIComponent(searchTerm)}*&%24orderby=search.score() desc,${option} ${order}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'api-key': 'ucao7YGRjMPe4XBtKycuHAb1655HAPiybrDPTqa5NOAzSeBFRzpy'
                }
            }
        );
        const data = await response.json();
        setProducts(data.value);
    } catch (error) {
        console.error('Error fetching search results:', error);
    } finally {
        setLoading(false);
        setOkToScroll(true);
    }
};