export const bottleReviews = [
    {
        id: 1,
        bottleName: "Bottle A",
        review: "A rich and flavorful bourbon with hints of vanilla and caramel. The finish is long and satisfying."
    },
    {
        id: 2,
        bottleName: "Bottle B",
        review: "Smooth and mellow with a unique fruity aroma. Notes of toasted oak and honey."
    },
    {
        id: 3,
        bottleName: "Bottle C",
        review: "A bold and robust flavor profile. Hints of dark chocolate and cherry make it a great choice for sipping."
    },
    // ... add more reviews as needed
];
