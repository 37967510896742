import { Card, CardBody, CardImg, CardText, CardTitle, Col, Container, Row } from 'reactstrap';
import { formatTitle } from '../Utilities/Utilities';
import './BourbonGrid.css';
import { useAppInsights } from '../AppInsights/AppInsightsContext';


function BourbonGrid(props) {
    const appInsights = useAppInsights();

    let filteredProducts = props.inStockOnly ? props.products.filter(product => product.availability) : props.products;
    filteredProducts = props.minimumPrice ? filteredProducts.filter(product => product.price_numeric >= Number(props.minimumPrice)) : filteredProducts;
    filteredProducts = props.maximumPrice ? filteredProducts.filter(product => product.price_numeric <= Number(props.maximumPrice)) : filteredProducts;

    const trackLinkClick = (product) => {
        appInsights.trackEvent({
            name: 'BourbonLinkClick',
            properties: {
                productName: product.title,
                link: product.itemLink,
            },
        });
    };

    return (
        <Container>
            <Row className="row-cols-1 row-cols-md-3 g-4">
                {filteredProducts.map((product, index) => (
                    <Col key={index} md={4}>
                        <Card style={{ padding: '0.5rem' }}>
                            <CardImg
                                top
                                src={product.iconUrl}
                                alt={product.title}
                                style={{
                                    width: '100%',
                                    height: '200px', // Adjust the height as needed
                                    objectFit: 'contain' // or 'cover' if you prefer
                                }}
                            />
                            <CardBody className="text-center">
                                <CardTitle className='card-title-custom '>
                                    <a 
                                    href={product.itemLink} 
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                    onClick={() => trackLinkClick(product)}>
                                        {formatTitle(product.title)}
                                    </a>
                                </CardTitle>
                                <CardText>{product.price}</CardText>
                                <CardText>{product.availability ? 'In Stock' : 'Out of Stock'}</CardText>
                                <CardText>
                                    <a href={product.url} target="_blank" rel="noopener noreferrer">
                                        {new URL(product.url).hostname}
                                    </a>
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                ))}
            </Row>
            {props.loading && <div className="loader"></div>}
        </Container>
    );
}

export default BourbonGrid